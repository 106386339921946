import React, {FC, useEffect, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";
import { ImMenu3, ImMenu4 } from "react-icons/im";
import moment from "moment";

import Row from "../Layout/Row";
import Col from "../Layout/Col";
import DateTimePickerField from "../Fields/DateTimePickerField";
import SelectField from "../Fields/SelectField";
import NumberField from "../Fields/NumberField";
import {ResourceType, SupplierAvailabilityType, SupplierType} from "../Calendar/CalendarResourceRow";
import {getBranchItemsConflicts} from "../../redux/selectors/branchItemsSelectors";
import CheckboxField from "../Fields/CheckboxField";
import {ResourceGroupsDataType} from "../../helpers/dataProcessor";
import {getResourceAddressOptionsFromAPI} from "../../helpers/api";
import TextField from "../Fields/TextField";
import { CalendarEventType } from "../Calendar/CalendarEventRow";
import Icon from "../Icon";
import Modal from "../Layout/Modal";
import ResourcesAddTable from "./ResourcesAddTable";
import { createSupplierListObject } from "../../helpers/calendarHelpers";
import PriceField from "../Fields/PriceField";
import { getEvents } from "../../redux/selectors/eventsSelectors";

interface Props {
	event: CalendarEventType;
	eventNotEditable: boolean;
    resources: ResourceType[];
	resourceGroups: ResourceGroupsDataType;
    onChange: (resources: ResourceType[]) => void;
}

const ResourcesEditTable: FC<Props> = ({ event, eventNotEditable, resources, resourceGroups, onChange }) => {
	const branchItemsConflicts = useSelector(getBranchItemsConflicts);
	const events = useSelector(getEvents);
	const [openedRow, setOpenedRow] = useState<string | null>(null);
	const [addResourceModalOpened, setAddResourceModalOpened] = useState(false);
    const { t } = useTranslation();
    // const suppliersOpts = useSelector(getSupplierFilterOptions);
	const allResources = events.flatMap(e => e.resources).filter(r => r.eventId !== event.id && r.addresses.length);

	useEffect(() => {
		const resourcesWithSupplierList = resources.map((res) => {
			return {...res, supplierlist: createSupplierListObject(res)};
		});
		onChange(resourcesWithSupplierList);
	}, []);

    const updateResource = (resourceId: string, key: string, value: any) => {
    	// TODO refactor
    	const resourceIdx = resources.findIndex(r => r.id === resourceId);
    	// @ts-ignore
		if (resourceIdx >= 0 && resources[resourceIdx][key] !== value) {
    		let newResources = [
				...resources.slice(0, resourceIdx),
				{
					...resources[resourceIdx],
					[key]: value
				},
				...resources.slice(resourceIdx + 1),
			]
			onChange(newResources);
			// onChange(resources.splice(resourceIdx, 1, {...resources[resourceIdx], [key]: value}));
		}
        // let thisResource: ResourceType | null = null;
        // const otherResources = resources.filter((r) => {
        //     if (r.id === resourceId) {
        //         thisResource = r;
        //         return false;
        //     }
        //     return true;
        // });
        // if (thisResource && thisResource[key] !== value) {
        //     onChange([
        //         ...otherResources,
        //         { ...(thisResource as ResourceType), [key]: value },
        //     ]);
        // }
    };

	const updateResourceKeys = (resourceId: string, map: any) => {
		const resourceIdx = resources.findIndex(r => r.id === resourceId);
		// @ts-ignore
		if (resourceIdx >= 0) {
			let newResources = [
				...resources.slice(0, resourceIdx),
				{
					...resources[resourceIdx],
					...map,
				},
				...resources.slice(resourceIdx + 1),
			]
			onChange(newResources);
		}
	};

    // const addResource = () => {
    //     const newResource: ResourceType = {
    //         id: "",
    //         name: null,
    //         startDate: null,
    //         endDate: null,
    //         color: null,
    //         status: null,
    //         eventId: null,
    //         itemId: null,
    //         branchId: null,
    //     };
    //     onChange([...resources, newResource]);
    // };

	const suppliersOpts = (addresses: SupplierType[]) => {
		return addresses.map(address => {
			return {
				value: address.id,
				label: address.name || '',
			}
		}).sort((a, b) => a.label.localeCompare(b.label));
	};

	const resourcesOpts = (groupId: string) => {
		if (groupId in resourceGroups) {
			return resourceGroups[groupId].map(resource => {
				return {
					value: resource.itemNr,
					label: resource.label || '',
				}
			})
		}

		return [];
	};

	const setItemIdAndAddresses = (resource: ResourceType, itemId: string | null) => {
		if (!itemId) return;
		const groupId = resource.itemTypeNr;
		if (groupId in resourceGroups) {
			const resourceOption = resourceGroups[groupId].find((r) => r.itemNr === itemId);
			if (!resourceOption) return;
			getResourceAddressOptionsFromAPI(resourceOption.itemNr, resourceOption.branchNr)
				.then((suppliers) => {
					updateResourceKeys(
						resource.id,
						{
							addresses: suppliers,
							addressnr: '0',
							itemId,
						}
					);
				})
				.catch((err) => console.log(err));
		}
	};

	const supplierPickedHadnler = (resource: ResourceType, supplier: string | null) => {
		const supplierlist = resource.supplierlist;
		if (!supplierlist) {
			updateResource(resource.id, "addressnr", supplier);
			return;
		}

		for (const key of Object.keys(supplierlist)) {
			if (key === supplier) {
				supplierlist[key].confirmFlag = true;
			} else {
				supplierlist[key].confirmFlag = false;
			}
		}
		updateResource(resource.id, "addressnr", supplier);
		updateResource(resource.id, "supplierlist", supplierlist);
	}

	const useStyles = makeStyles({
		tableContainer: {
			marginBottom: 10,
		},
		tableRoot: {
			'& .MuiTableCell-sizeSmall': {
				padding: '0 10px',
			},
			'& .field-container': {
				margin: 0,
			}
		}
	});
	const classes = useStyles();

	const getSupplierName = (resource: ResourceType, addressNr: string) => {
		const address = resource.addresses.find((adr) => adr.id === addressNr);
		if (!address) return '!!Unknown!!';
		return address.name;
	}

	// @ts-ignore
	function createData(name, inviteFlag, invitedAt, acceptedAt, onHoldAt, denyFlag, deniedAt) {
		return { name, inviteFlag, invitedAt, acceptedAt, onHoldAt, denyFlag, deniedAt };
	}

	const checkIfSupplierIsBooked = (resource: ResourceType, addressNr: string): boolean => {
		// console.table(resource);
		// console.log(addressNr);
		// console.log(allResources);
		const filteredResources = allResources.filter((r) => {
			const r1StartDate = moment(resource.startDate);
			const r1EndDate = moment(resource.endDate);
			const r2StartDate = moment(r.startDate);
			const r2EndDate = moment(r.endDate);
			const overlap = (r1StartDate < r2EndDate) && (r2StartDate < r1EndDate);
			return r.addressnr === addressNr && overlap
		});
		console.log(filteredResources);
		return !!filteredResources.length;
	}

	const prepareSupplierRows = (resource: ResourceType) => {
		if (!resource.supplierlist) return [];
		const rows: any = [];
		Object.keys(resource.supplierlist).forEach((key) => {
			const supplier = resource.supplierlist?.[key];
			if (!supplier) return;
			rows.push(
				createData(
					getSupplierName(resource, key),
					<CheckboxField
						disabled={checkIfSupplierIsBooked(resource, key)}
						label={''}
						value={supplier.inviteFlag || false}
						onChange={(v) => {
							const newList = {
								...resource.supplierlist,
								[key]: {...supplier, inviteFlag: v},
							}
							updateResource(resource.id, 'supplierlist', newList)
						}}
					/>,
					supplier.invitedAt ? <Chip size="small" label={supplier.invitedAt} /> : '',
					supplier.acceptedAt ? <Chip size="small" color="primary" label={supplier.acceptedAt} /> : '',
					supplier.onHoldAt ? <Chip size="small" label={supplier.onHoldAt} /> : '',
					<CheckboxField
						label={''}
						value={supplier.denyFlag || false}
						onChange={(v) => {
							const newList = {
								...resource.supplierlist,
								[key]: {...supplier, denyFlag: v},
							}
							updateResource(resource.id, 'supplierlist', newList)
						}}
					/>,
					supplier.deniedAt ? <Chip size="small" color="secondary" label={supplier.deniedAt} /> : '',
				)
			)
		});

		rows.sort((a: any, b: any) => a.name.localeCompare(b.name));
		return rows;
	}

	const sortedResources = useMemo(() => {
		const tickets = resources.filter((r) => r.controlSequence === 'T');
		const other = resources.filter((r) => r.controlSequence !== 'T');

		tickets.sort((a, b) => a.label!.localeCompare(b.label!));
		other.sort((a, b) => a.label!.localeCompare(b.label!));

		return [...tickets, ...other];
	}, [resources]);

    return (
        <>
            <Row>{t("resources")}:</Row>
            <Row>
                <Col width={0.5}>{''}</Col>
                <Col width={2.4}>{t("from")}</Col>
                <Col width={2.4}>{t("to")}</Col>
                <Col width={2.4}>{t("group")}</Col>
                <Col width={3.6}>{t("resources")}</Col>
                <Col width={0.6}>{t("min")}</Col>
                <Col width={0.6}>{t("max")}</Col>
                <Col width={0.6}>{t("quantity")}</Col>
                <Col width={1}>{t("price")}</Col>
                <Col width={2.6}>{t("supplier")}</Col>
				<Col width={0.5}>{''}</Col>
            </Row>
            {sortedResources.map((r) => (
				<>
					<Row
						className={branchItemsConflicts.includes(r.id) ? 'row-collision sm' : 'sm'}
						gap="2px"
						key={r.id}
					>
						<Col width={0.5}>
							<CheckboxField
								label={''}
								value={r.status || false}
								disabled={eventNotEditable}
								onChange={(v) => {
									updateResource(r.id, "status", v)
								}}
							/>
						</Col>
						<Col width={2.4}>
							<DateTimePickerField
								value={r.startDate}
								disabled={eventNotEditable}
								onChange={(date) =>
									updateResource(r.id, "startDate", date)
								}
							/>
						</Col>
						<Col width={2.4}>
							<DateTimePickerField
								value={r.endDate}
								disabled={eventNotEditable}
								onChange={(date) =>
									updateResource(r.id, "endDate", date)
								}
							/>
						</Col>
						<Col width={2.4} className={'border-bb'}>
							{/*<p style={{marginLeft: '0.2rem'}}>{r.label}</p>*/}
							<TextField
								value={r.label}
								disabled={eventNotEditable}
								onChange={() => {}}
								readOnly={true}
							/>
						</Col>
						<Col width={3.6} className={'border-bb'}>
							<SelectField<string>
								value={r.itemId || null}
								disabled={eventNotEditable}
								onChange={(item) => {
									if (!resourcesOpts(r.itemTypeNr).length) return;
									setItemIdAndAddresses(r, item || null);
								}}
								options={resourcesOpts(r.itemTypeNr)}
							/>
						</Col>
						<Col width={0.6}>
							<NumberField value={r.minStock} disabled={eventNotEditable || r.controlSequence !== 'T'} onChange={(v) => {updateResource(r.id, "minStock", v)}} />
						</Col>
						<Col width={0.6}>
							<NumberField value={r.fullStock} disabled={eventNotEditable || r.controlSequence !== 'T'} onChange={(v) => {updateResource(r.id, "fullStock", v)}} />
						</Col>
						<Col width={0.6}>
							<NumberField value={r.currentStock} disabled={eventNotEditable} onChange={(v) => {updateResource(r.id, "currentStock", v)}} />
						</Col>
						<Col width={1}>
							<PriceField value={r.actualPrice} disabled={eventNotEditable} onChange={(v) => {updateResource(r.id, "actualPrice", v)}} decimals={2} />
						</Col>
						<Col width={2.6}>
							<SelectField<string>
								value={r.addressnr ?? "0"}
								onChange={(supplier) => supplierPickedHadnler(r, supplier)}
								options={suppliersOpts(r.addresses)}
							/>
						</Col>
						<Col width={0.5} justify={'center'} align={'center'}>
							{!(openedRow === r.id) ? (
								<ImMenu3
									name="add"
									size="22px"
									onClick={() => setOpenedRow(r.id)}
								/>
							) : (
								<ImMenu4
									name="remove"
									size="22px"
									onClick={() => setOpenedRow(null)}
								/>
							)}
						</Col>
					</Row>
					{openedRow === r.id && (
						<Row>
							<Col width={6.5}>{''}</Col>
							<Col width={9}>
								<TableContainer className={classes.tableContainer} component={Paper}>
									<Table classes={{root: classes.tableRoot}} size="small" aria-label="a dense table">
										<TableHead>
											<TableRow>
												<TableCell>{t('supplier')}</TableCell>
												<TableCell align="center">{t("invite")}</TableCell>
												<TableCell align="center">{t("invited")}</TableCell>
												<TableCell align="center">{t("accepted")}</TableCell>
												<TableCell align="center">{t("on_hold")}</TableCell>
												<TableCell align="center">{t("deny")}</TableCell>
												<TableCell align="center">{t("denied")}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{prepareSupplierRows(r).map((row: any) => (
												<TableRow key={row.name}>
													<TableCell component="th" scope="row">
														{row.name}
													</TableCell>
													<TableCell align="center">
														{row.inviteFlag}
													</TableCell>
													<TableCell align="center">{row.invitedAt}</TableCell>
													<TableCell align="center">{row.acceptedAt}</TableCell>
													<TableCell align="center">{row.onHoldAt}</TableCell>
													<TableCell align="center">
														{row.denyFlag}
													</TableCell>
													<TableCell align="center">{row.deniedAt}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Col>
							<Col width={0.5}>{''}</Col>
						</Row>
					)}
				</>
            ))}
            <Row>
            	<Icon name="add" size="24px" onClick={() => eventNotEditable ? null : setAddResourceModalOpened(true)} />
            </Row>
			{addResourceModalOpened && (
				<Modal
					open={true}
					eventModal={false}
					onClose={() => setAddResourceModalOpened(false)}
					title={t("add_resource")}
					showCloseBtn={true}
					maxWidth="lg"
				>
					<ResourcesAddTable event={event} onPick={onChange} />
				</Modal>
			)}
        </>
    );
};

export default ResourcesEditTable;
