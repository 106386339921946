import { format } from "date-fns";
import { nanoid } from 'nanoid'

import { CalendarEventType } from "../components/Calendar/CalendarEventRow";
import {ResourceType, SupplierType} from "../components/Calendar/CalendarResourceRow";
import { FiltersOptionsType } from "../redux/reducers/filterOptionsReducer";
import {BillType} from "../components/Calendar/CalendarPositionsRow";
import { ResourceItemType } from "./api";
import { CalendarSettingsType } from "../redux/reducers/calendarReducer";

export interface TemplateWithGroupType {
	value: string;
	label: string;
	subject: string;
	billNr: string;
	branchNr: string;
	hostNr: string;
	group: string;
	groupNr: string;
}

interface FilterAPIDataType {
    calendars: { calendid: string; calendname: string }[];
    country: { CountryNr: string; CountryName: string }[];
    branches: {
        BranchNr: string;
        CountryNr: string;
        BranchName: string;
    }[];
    templateGroups: {
		name: string;
		TemplateGroupNr: string;
		templates: {
			description: string;
			subject: string;
			billnr: string;
			branchnr: string;
			hostnr: string;
			// EventNr: string;
			// EventName: string;
		}[];
    }[];
    resourceGroup: {
        ItemTypeNr: string;
        Label: string;
    }[];
    resources: {
        ItemNr: string;
        BranchNr: string;
        ItemName: string;
        ItemType: string;
    }[];
    suppliers: {
        name: string;
        AddressNr: string;
    }[];
}

function preprocessResourceSuppliersData(addresses: any[]): SupplierType[] {
	return (addresses || []).map((address, index) => {
		return {
			id: address.AddressNr,
			name: `${address.Name1 || ''} ${address.Name2 || ''}`,
		};
	});
}

function preprocessResourcesData(resources: any[]): ResourceType[] {
    return (resources || []).map((resource, index) => {
        return {
            // id: "e" + resource.EventNr + "-res" + index,
            id: `e${resource.EventNr}-res${index}-bri${resource.ItemNr}`,
            name: resource.ItemName,
			addresses: preprocessResourceSuppliersData(resource.Addresses),
            startDate: new Date(resource.FromDate),
            endDate: new Date(resource.ToDate),
            minStock: parseInt(resource.MinStock),
            currentStock: parseInt(resource.CurrentStock),
            fullStock: parseInt(resource.FullStock),
            actualPrice: parseFloat(resource.Actualprice),
            label: resource.Label,
            itemName: resource.ItemName,
            status: ['-1', '5'].includes(resource.Status),
            eventId: resource.EventNr,
            eventItemNr: resource.EventItemNr,
            itemId: resource.ItemNr,
            oldItemId: resource.ItemNr,
            branchId: resource.BranchNr,
			addressnr: resource.addressnr,
			prepTime: resource.Preptime,
			followupTime: resource.Followuptime,
			itemTypeNr: resource.ItemTypeNr,
			supplierlist: JSON.parse(resource.Supplierlist),
			controlSequence: resource.ControlSequence,
			positionId: resource.PositionID,
        };
    });
}

function preprocessResourceItemsData(resources: any[]): ResourceItemType[] {
    return (resources || []).map((resource, index) => {
        return {
            fullStock: parseInt(resource.FullStoreQty),
            actualPrice: parseInt(resource.ActualPrice),
            label: resource.Label,
            itemName: resource.ItemName,
            itemId: resource.ItemNr,
			controlSequence: resource.ControlSequence,
			itemTypeNr: resource.ItemTypeNr,
        };
    });
}

function preprocessBillsData(bills: any): BillType {
	return {
		positions: [],
		adressName1Name2: bills?.adressName1Name2,
	};
}

export interface BranchItemType {
	branchNr: string;
	itemNr: string;
	itemName: string;
	fullStockQty: number;
	fullStoreQty: number;
}

export interface EventWithBranchItemType {
	events: CalendarEventType[];
	branchItems: BranchItemType[];
}

function preprocessEventsWithBranchItemsData(events: any[], branchItems: any[]): EventWithBranchItemType {
	return {
		events: preprocessEventData(events),
		branchItems: preprocessBranchItemsData(branchItems),
	}
}

function preprocessBranchItemsData(branchItems: any[]): BranchItemType[] {
	return (branchItems || []).map((branchItem) => {
		return {
			branchNr: branchItem.BranchNr,
			itemNr: branchItem.ItemNr,
			itemName: branchItem.ItemName,
			fullStockQty: parseInt(branchItem.FullStockQty),
			fullStoreQty: parseInt(branchItem.FullStoreQty),
		};
	});
}

function preprocessEventData(events: any[]): CalendarEventType[] {
    return (events || []).map((event) => {
        return {
            id: event.EventNr,
            originalId: event.OriginalEventNr || event.EventNr,
            name: event.EventName,
			sourceTemplateName: event.sourceTemplateName,
            description: event.EventDescription,
            templateId: event.TemplateNr || null,
            sourceTemplateId: event.SourceTemplateNr !== '0' ? event.SourceTemplateNr : event.TemplateNr,
            startDate: new Date(event.Eventfromdate),
            endDate: new Date(event.Eventtodate),
			timestamp: new Date(event.timestamp),
            branchName: event.BranchName,
            branchId: event.BranchNr,
            resources: preprocessResourcesData(event.resources),
            bills: preprocessBillsData(event.bills),
            color: event.Eventcolor || undefined,
            parentEventId: event.ParentEventNr,
            parentSeries: event.ParentSeries,
            user: event.UserNr,
            bgColor: event.backcolnr,
            frameColor: event.framecolnr,
            frameType: event.frametypenr,
            status: event.status,
            lastChange: event.timestamp,
            calendarId: event.CalendarNr,
            orderLink: event.foreignlink,
            orderLinkType: event.foreignlinktype,
            exclusive: event.exclusive === "-1",
            isBlocked: event.blocked === "-1",
            updated: false,
			minVisitors: parseInt(event.minvisitors),
			maxVisitors: parseInt(event.maxvisitors),
			currentVisitors: parseInt(event.currvisitors),
			offlineVisitors: parseInt(event.offvisitors),
			offsetOffline: parseInt(event.offsetoffline),
			contingented: event.contingented === "-1",
			originalTemplateName: event.OriginalTemplateName,
			locked: false,
        };
    });
}

export interface TemplateDataType {
	otherEvents: CalendarEventType[];
	branchItems: BranchItemType[];
	event: CalendarEventType;
}

function preprocessTemplateData(template: any): TemplateDataType {
	let resources = template.template.event?.resources;
	if (resources) {
		resources = resources.map((r: any) => {
			return {...r, EventNr: 'virtual'};
		})
	} else {
		resources = [];
	}
	return {
		otherEvents: preprocessEventData(template.events),
		branchItems: preprocessBranchItemsData(template.branchitems),
		event: preprocessEventData([
			{
				...template.template.event,
				EventNr: 'virtual',
				OriginalEventNr: template.template.event.EventNr,
				resources: resources,
				OriginalTemplateName: template.template.description,
			}
		])[0],
	}
}

export interface EventForPlanningDataType {
	events: CalendarEventType[];
	branchItems: BranchItemType[];
	event: CalendarEventType;
}

function preprocessEventForPlanningData(data: any): EventForPlanningDataType {
	return {
		events: preprocessEventData(data.events),
		branchItems: preprocessBranchItemsData(data.branchitems),
		event: preprocessEventData([data.event])[0],
	}
}

export interface ResourceOptionType {
	itemNr: string;
	label: string;
	branchNr: string;
}

export interface ResourceGroupsDataType {
	[key: string]: ResourceOptionType[]
}

function preprocessResourceGroupsData(data: any): ResourceGroupsDataType {
	const res: ResourceGroupsDataType = {};
	for (const [key, value] of Object.entries(data.resourceGroups)) {
		// @ts-ignore
		res[key] = value.resources.map((r) => {
			return {
				itemNr: r.ItemNr,
				label: r.ItemName,
				branchNr: r.BranchNr,
			}
		});
	}

	return res;
}

function processResourcesForUpload(resources: ResourceType[]) {
    return (resources || []).map((resource) => {
        return {
            EventNr: resource.eventId,
            ItemNr: resource.itemId,
            OldItemNr: resource.oldItemId,
            BranchNr: resource.branchId,
            FromDate: resource.startDate
                ? format(resource.startDate, "yyyy-MM-dd HH:mm:ss")
                : undefined,
            ToDate: resource.endDate
                ? format(resource.endDate, "yyyy-MM-dd HH:mm:ss")
                : undefined,
            MinStock: resource.minStock,
            CurrentStock: resource.currentStock,
            FullStock: resource.fullStock,
			Actualprice: resource.actualPrice,
            addressnr: resource.addressnr ?? 0,
			Status: resource.status ? '-1' : '0',
			EventItemNr: resource.eventItemNr,
			Preptime: resource.prepTime,
			Followuptime: resource.followupTime,
			Supplierlist: resource.supplierlist ? JSON.stringify(resource.supplierlist) : null,
			PositionID: resource.positionId,
        };
    });
}

function processEventsForUpload(events: CalendarEventType[]) {
    return (events || []).map((event) => {
        return {
            EventNr: event.id,
            EventName: event.name,
            EventDescription: event.description,
            Eventfromdate: format(event.startDate, "yyyy-MM-dd HH:mm:ss"),
            Eventtodate: format(event.endDate, "yyyy-MM-dd HH:mm:ss"),
            ...(event.resources.length
                ? { resources: processResourcesForUpload(event.resources) }
                : {}),
            backcolnr: event.bgColor,
            framecolnr: event.frameColor,
            frametypenr: event.frameType,
            timestamp: format(new Date(), "yyyy-MM-dd HH:mm:ss"), // TODO: nastavovat na serveri
            CalendarNr: event.calendarId,
            exclusive: event.exclusive ? "-1" : "0",
            blocked: event.isBlocked ? "1" : "0",
        };
    });
}

function processEventForUpload(event: CalendarEventType) {
	return {
		EventNr: event.id,
		BranchNr: event.branchId,
		EventName: event.name,
		EventDescription: event.description,
		SourceTemplateNr: event.sourceTemplateId,
		Eventcolor: event.color,
		Eventfromdate: format(event.startDate, "yyyy-MM-dd HH:mm:ss"),
		Eventtodate: format(event.endDate, "yyyy-MM-dd HH:mm:ss"),
		...(event.resources.length
			? { resources: processResourcesForUpload(event.resources) }
			: {}),
		backcolnr: event.bgColor,
		framecolnr: event.frameColor,
		frametypenr: event.frameType,
		timestamp: format(new Date(), "yyyy-MM-dd HH:mm:ss"), // TODO: nastavovat na serveri
		CalendarNr: event.calendarId,
		exclusive: event.exclusive ? "-1" : "0",
		contingented: event.contingented ? "-1" : "0",
		blocked: event.isBlocked ? "1" : "0",
		offsetoffline: event.offsetOffline,
		offvisitors: event.offlineVisitors,
		minvisitors: event.minVisitors,
		maxvisitors: event.maxVisitors,
		currvisitors: event.currentVisitors.toString(),
	};
}

function preprocessFilterOptionsData(
    filterData: FilterAPIDataType
): FiltersOptionsType {
	const templates: TemplateWithGroupType[] = [];
	let i = 0;
	filterData.templateGroups.forEach((g) => {
		g.templates.forEach((t) => {
			templates.push({
				value: i.toString(), // TODO temp
				label: t.description,
				subject: t.subject,
				billNr: t.billnr,
				branchNr: t.branchnr,
				hostNr: t.hostnr,
				group: g.name,
				groupNr: g.TemplateGroupNr,
			});
			i++;
		});
	});

	const calendars = filterData.calendars.map((c) => {
		return {
			value: c.calendid,
			label: c.calendname,
		};
	});
	calendars.push({value: '-1', label: 'all_calendars'});

	return {
        calendars: calendars,
        branchGroup: filterData.country.map((c) => {
            return {
                value: c.CountryNr,
                label: c.CountryName,
            };
        }),
        branch: filterData.branches.map((b) => {
            return {
                value: b.BranchNr,
                label: b.BranchName,
                groupValue: b.CountryNr,
            };
        }),
        template: templates,
		grouppedTemplates: filterData.templateGroups.map((g) => {
			return {
				label: g.name,
				templateGroupNr: g.TemplateGroupNr,
				options: g.templates.map((t, idx) =>
					{
						return {
							label: t.description,
							subject: t.subject,
							billNr: t.billnr,
							branchNr: t.branchnr,
							hostNr: t.hostnr,
							group: g.name,
							groupNr: g.TemplateGroupNr,
							value: nanoid(8),
						}
					}
				)
			}
		}),
		templateGroup: filterData.templateGroups.map((g) => {
			return {
				value: g.TemplateGroupNr || 'unknown',
				label: g.name,
			}
		}),
        resourceGroup: filterData.resourceGroup.map((res) => {
            return {
                value: res.ItemTypeNr,
                label: res.Label,
            };
        }),
        resource: filterData.resources.map((res) => {
            return {
                value: res.ItemNr,
                label: res.ItemName,
				branchId: res.BranchNr,
                groupValue: res.ItemType,
            };
        }),
        suppliers: filterData.suppliers.map((sup) => {
            return {
                value: sup.AddressNr,
                label: sup.name,
            };
        }),
    };
}

function preprocessCalendarSettingsData(res: any) {
	const result: CalendarSettingsType = {};
	for (const [key, settings] of Object.entries(res.settingsCalendar as CalendarSettingsType)) {
		const workTimeStartSplit = settings.DisplayHoursFrom;
		const workTimeEndSplit = settings.DisplayHoursTo;
		result[key] = {
			...settings,
			workTimeStart: {
				hours: parseInt(workTimeStartSplit),
				minutes: 0,
			},
			workTimeEnd: {
				hours: parseInt(workTimeEndSplit),
				minutes: 0,
			},
			startView: settings.CalenderStartView.toLowerCase(),
		};
	}

	return result;
}

export {
    preprocessResourcesData,
    preprocessEventData,
    processEventsForUpload,
	processEventForUpload,
    preprocessFilterOptionsData,
    preprocessCalendarSettingsData,
	preprocessEventsWithBranchItemsData,
	preprocessTemplateData,
	preprocessEventForPlanningData,
	preprocessResourceGroupsData,
	preprocessResourceSuppliersData,
	preprocessResourceItemsData,
};
