import config from "../config.json";

function objectsEquals(x: any, y: any) {
    let objectsAreSame = true;
    for (let propertyName in x) {
        if (
            x[propertyName] instanceof Date &&
            y[propertyName] instanceof Date
        ) {
            if (x[propertyName].getTime() !== y[propertyName].getTime()) {
                objectsAreSame = false;
                break;
            }
        } else {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
    }
    return objectsAreSame;
}

const filterTemplatesByBranchId = (templateGroups: any, branchId: any, templateGroupId?: any) => {
	if (!branchId && !templateGroupId) return templateGroups;
	let result: any = [];
	templateGroups.forEach((group: any) => {
		const templates = group.options.filter((template: any) => template.branchNr === branchId);
		if (templates.length) {
			result.push({...group, options: templates});
		}
	});

    if (templateGroupId) {
        result = result.filter((group: any) => group.templateGroupNr === templateGroupId);
    }

	return result;
}

export const isDevEnvironment = new RegExp(config.devEnvs).test(window.location.host);

export { objectsEquals, filterTemplatesByBranchId };
