import React, {FC, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { nanoid } from 'nanoid'

import { getResourceItems } from "../../redux/selectors/branchItemsSelectors";
import Row from "../Layout/Row";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, TextField } from "@material-ui/core";
import { getResourceAddressOptionsFromAPI, ResourceItemType } from "../../helpers/api";
import ButtonField from "../Fields/ButtonField";
import { ResourceType, SupplierType } from "../Calendar/CalendarResourceRow";
import { CalendarEventType } from "../Calendar/CalendarEventRow";
import { createSupplierListObject } from "../../helpers/calendarHelpers";

interface Props {
    event: CalendarEventType;
    onPick: (resources: ResourceType[]) => void;
}

const ResourcesAddTable: FC<Props> = ({event, onPick}) => {
    const [loading, setLoading] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [filteredResourceItems, setFilteredResourceItems] = useState<ResourceItemType[]>([]);
    const resourceItems = useSelector(getResourceItems);
    const { t } = useTranslation();

    const useStyles = makeStyles({
		tableContainer: {
            marginTop: 10,
			marginBottom: 10,
		},
		tableRoot: {
			'& .MuiTableCell-sizeSmall': {
				padding: '5px 15px',
			},
			'& .field-container': {
				margin: 0,
			}
		}
	});
	const classes = useStyles();

    useEffect(() => {
        let resources: ResourceItemType[] = [];
        console.log(resourceItems);
        if (filterName) {
            resources = resourceItems.filter((item) => {
                return item.itemName.toLowerCase().includes(filterName.toLowerCase())
                || item.label.toLowerCase().includes(filterName.toLowerCase());
            });
        } else {
            resources = resourceItems;
        }

        resources.sort((a, b) =>  a.itemName.localeCompare(b.itemName));
        setFilteredResourceItems(resources)

    }, [filterName, resourceItems]);

    const getResourceItemAddresses = async (item: ResourceItemType): Promise<SupplierType[]> => {
        if (!event.branchId) return [];
        try {
            return await getResourceAddressOptionsFromAPI(item.itemId, event.branchId);
        } catch(e) {
            console.error(e);
            return [];
        }
    }

    const pickResourceHandler = async (resourceItem: ResourceItemType) => {
        setLoading(true);
        const addresses = await getResourceItemAddresses(resourceItem);
        const resource: ResourceType = {
            id: nanoid(8),
            eventItemNr: 'new',
            eventId: event.id,
            name: resourceItem.itemName,
            addresses: addresses,
            startDate: event.startDate,
            endDate: event.endDate,
            minStock: 0,
            currentStock: 1,
            fullStock: resourceItem.fullStock,
            actualPrice: resourceItem.actualPrice,
            label: resourceItem.label,
            itemName: resourceItem.itemName,
            itemTypeNr: resourceItem.itemTypeNr,
            supplierlist: null,
            controlSequence: resourceItem.controlSequence,
            status: true,
            addressnr: "0",
            branchId: event.branchId,
            itemId: resourceItem.itemId,
            oldItemId: resourceItem.itemId,
            positionId: "",
        }

        resource.supplierlist = createSupplierListObject(resource);
        onPick([...event.resources, resource]);
        setLoading(false);
        toast.success(t('Resource added'));
    };

    return (
        <>
            <Row>
                <TextField
                    value={filterName}
                    onChange={(val) => setFilterName(val.target.value)}
                    label={t("search")}
                    variant="outlined"
                />
            </Row>
            <Row>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table classes={{root: classes.tableRoot}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('name')}</TableCell>
                                <TableCell align="center">{t('group')}</TableCell>
                                <TableCell align="center">{t('price')}</TableCell>
                                <TableCell align="center">{t('full_stock')}</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredResourceItems.map((row: ResourceItemType) => (
                                <TableRow key={row.itemId}>
                                    <TableCell component="th" scope="row">
                                        {row.itemName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.label}
                                    </TableCell>
                                    <TableCell align="center">{row.actualPrice}</TableCell>
                                    <TableCell align="center">{row.fullStock}</TableCell>
                                    <TableCell align="center">
                                        <ButtonField
                                            color="primary"
                                            size="small"
                                            onClick={() => pickResourceHandler(row)}
                                            disabled={loading}
                                        >
                                            {t("Add")}
                                        </ButtonField>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Row>
        </>
    );
}

export default ResourcesAddTable;