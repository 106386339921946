import { CalendarDisplayType } from "../../components/Calendar/Calendar";
import { FiltersState } from "../reducers/filtersReducer";

export const getFilters = (store: any): FiltersState => {
    return store.filters;
};

export const getPickedDate = (store: any): Date => {
    return store.filters.pickedDate;
};

export const getCalendarDisplayType = (store: any): CalendarDisplayType => {
    return store.filters.calendarDisplayType;
};

export const getCalendarId = (store: any): string | null => {
    return store.filters.calendarId;
};

export const getBranchFilter = (store: any): string | null => {
    return store.filters.branch;
};

export const getTemplateGroupFilter = (store: any): string | null => {
    return store.filters.templateGroup;
};

export const getShowBlockersFilter = (store: any): boolean => {
    return store.filters.showBlockers;
};

