import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Select from "react-select";

import Row from "../Layout/Row";
import ButtonField from "../Fields/ButtonField";
import Col from "../Layout/Col";
import { useGetDayName } from "../../helpers/dateHelpers";
import TimeLine from "./TimeLine";
import { getTimeLineValues } from "../../helpers/calendarHelpers";
import CreateEventCalendarBody from "./CreateEventCalendarBody";
import CalendarBody from "./CalendarBody";
import EventSort from "./EventSort";
import {
    getCalendarSettings,
    getCalendarStartDate,
    getCreateEventMode,
    getWorkTimeEnd,
    getWorkTimeStart,
} from "../../redux/selectors/calendarSelectors";
import {
	getGrouppedTemplatesFilterOptions,
} from "../../redux/selectors/filterOptionsSelectors";
import {
    setCreateEventMode,
    setSelectedTemplateId,
	setSelectedEventIdForPlanning,
} from "../../redux/actions/calendarActions";
import {filterTemplatesByBranchId} from "../../helpers/helpers";
import {getBranchFilter, getTemplateGroupFilter} from "../../redux/selectors/filterSelectors";

const DayCalendar: FC = () => {
    const dispatch = useDispatch();
    const templateOpts = useSelector(getGrouppedTemplatesFilterOptions);
	const branchId = useSelector(getBranchFilter);
    const templateGroupId = useSelector(getTemplateGroupFilter);
    const calendarStartDate = useSelector(getCalendarStartDate);
    const workTimeStart = useSelector(getWorkTimeStart);
    const workTimeEnd = useSelector(getWorkTimeEnd);
    const createEventMode = useSelector(getCreateEventMode);
    const calendarSettings = useSelector(getCalendarSettings);
    const timeLineValues = getTimeLineValues(workTimeStart, workTimeEnd, "day");
    const { t } = useTranslation();

    // const handleCreateEvent = () => {
    //     newEvent &&
    //         createEventFromAPI(newEvent)
    //             .then(() => {
    //                 // console.log("created event", newEvent);
    //             })
    //             .catch((err) => console.log(err));
    //     // TODO: update resources
    // };

    return (
        <div className="calendar-container">
            <div className="calendar-head">
                <Row>
                    <Col width={1} className="calendar-head-event">
                        <Row gap="5px">
                            {!createEventMode ? (
                                <Col>
									<Select
										placeholder={t("template")}
										menuPortalTarget={document.body}
										styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
										options={filterTemplatesByBranchId(templateOpts, branchId, templateGroupId)}
										onChange={(val) => {
											dispatch(
												// @ts-ignore
												setSelectedTemplateId(val.value)
											);
											val &&
											dispatch(
												setCreateEventMode(true)
											);
										}}
									/>
                                    <EventSort />
                                    {/* <Row gap="5px">
                                        <Col>
                                            <ButtonField
                                                className="add-event-button"
                                                size="small"
                                                onClick={() => sortEvents('name')}
                                            >
                                                {t("Updated")}
                                            </ButtonField>
                                        </Col>
                                        <Col>
                                            <ButtonField
                                                className="add-event-button"
                                                size="small"
                                                onClick={() => sortEvents('name')}
                                            >
                                                {t("name")}
                                            </ButtonField>
                                        </Col>
                                        <Col>
                                            <ButtonField
                                                className="add-event-button"
                                                size="small"
                                                onClick={() => sortEvents('startDate')}
                                            >
                                                {t("Time")}
                                            </ButtonField>
                                        </Col>
                                    </Row> */}
                                    {/*<SelectField<string>*/}
                                    {/*    value={selectedTemplateId}*/}
                                    {/*    onChange={(val) => {*/}
                                    {/*        dispatch(*/}
                                    {/*            setSelectedTemplateId(val)*/}
                                    {/*        );*/}
                                    {/*        val &&*/}
                                    {/*            dispatch(*/}
                                    {/*                setCreateEventMode(true)*/}
                                    {/*            );*/}
                                    {/*    }}*/}
                                    {/*    label={t("template")}*/}
                                    {/*    options={templateOpts}*/}
                                    {/*/>*/}
                                </Col>
                            ) : (
                                <>
                                    {/*<Col>*/}
                                    {/*    <ButtonField*/}
                                    {/*        className="add-event-button"*/}
                                    {/*        onClick={handleCreateEvent}*/}
                                    {/*    >*/}
                                    {/*        {t("create_event")}*/}
                                    {/*    </ButtonField>*/}
                                    {/*</Col>*/}
                                    <Col>
                                        <ButtonField
                                            className="add-event-button"
                                            onClick={() => {
                                                dispatch(
                                                    setSelectedTemplateId(null)
                                                );
												dispatch(
													setSelectedEventIdForPlanning(null)
												);
                                                dispatch(
                                                    setCreateEventMode(false)
                                                );
                                            }}
                                        >
                                            {t("cancel")}
                                        </ButtonField>
                                        <EventSort />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                    <Col width={7} align="center" className="calendar-head-day">
                        {useGetDayName(calendarStartDate)}
                        <br />
                        {format(calendarStartDate, "dd.MM.yyyy")}
                    </Col>
                </Row>
            </div>
            <TimeLine timeLineValues={timeLineValues} />
            <div className="calendar-body-container">
                <div className="calendar-body">
                    <div className="body-timeline">
                        {timeLineValues.map((tl, index) => (
                            <React.Fragment key={index}>
                                {tl.showLine && (
                                    <div
                                        className="line"
                                        style={{
                                            marginLeft: `${tl.step}px`,
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    {createEventMode ? (
                        <CreateEventCalendarBody />
                    ) : (
                        <CalendarBody />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DayCalendar;
