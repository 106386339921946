import { WorkTime } from "../../components/Calendar/Calendar";
import { CalendarEventType } from "../../components/Calendar/CalendarEventRow";
import { CalendarActionTypes } from "../actionTypes";

export interface CalendarSettingsType {
    [key: string]: {
        [key: string]: any;
    };
}

export type EventSortOptionType = 'timestamp' | 'name' | 'startDate';

export interface CalendarDataType {
    calendarStartDate: Date;
    calendarEndDate: Date;
    workTimeStart: WorkTime;
    workTimeEnd: WorkTime;
    checkedEventIds: string[];
    openedDetails: string[];
    createEventMode: boolean;
    salesMode: boolean;
    selectedEvent: CalendarEventType | null;
    selectedTemplateId: string | null;
    selectedEventIdForPlanning: string | null;
    newEvent: CalendarEventType | null;
    isLoading: boolean;
    shouldAutoRefresh: boolean;
    calendarSettings: CalendarSettingsType;
    eventSortOption: EventSortOptionType;
}

const initCalendarData: CalendarDataType = {
    calendarStartDate: new Date(),
    calendarEndDate: new Date(),
    workTimeStart: { hours: 0, minutes: 0 },
    workTimeEnd: { hours: 23, minutes: 59 },
    checkedEventIds: [],
    openedDetails: [],
    createEventMode: false,
    salesMode: false,
    selectedEvent: null,
    newEvent: null,
    selectedTemplateId: null,
	selectedEventIdForPlanning: null,
    isLoading: false,
	shouldAutoRefresh: true,
    calendarSettings: {},
    eventSortOption: "timestamp" as EventSortOptionType,
};

function calendarReducer(
    state: CalendarDataType = initCalendarData,
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case CalendarActionTypes.SET_CALENDAR_START_DATE:
            // console.log("REDUX - calendar calendarStartDate");
            return { ...state, calendarStartDate: action.payload.value };
        case CalendarActionTypes.SET_CALENDAR_END_DATE:
            // console.log("REDUX - calendar calendarEndDate");
            return { ...state, calendarEndDate: action.payload.value };
        case CalendarActionTypes.SET_WORKTIME_START:
            // console.log("REDUX - calendar workTimeStart");
            return { ...state, workTimeStart: action.payload.value };
        case CalendarActionTypes.SET_WORKTIME_END:
            // console.log("REDUX - calendar workTimeEnd");
            return { ...state, workTimeEnd: action.payload.value };
        case CalendarActionTypes.SET_CHECKED_EVENT_IDS:
            // console.log("REDUX - calendar checkedEventIds (set)");
            return {
                ...state,
                checkedEventIds: action.payload.value,
            };
        case CalendarActionTypes.ADD_CHECKED_EVENT_IDS:
            // console.log("REDUX - calendar checkedEventIds (add)");
            return {
                ...state,
                checkedEventIds: [
                    ...state.checkedEventIds,
                    ...action.payload.value,
                ],
            };
        case CalendarActionTypes.SET_OPENED_DETAILS:
            // console.log("REDUX - calendar openedDetails (set)");
            return {
                ...state,
                openedDetails: action.payload.value,
            };
        case CalendarActionTypes.ADD_OPENED_DETAIL:
            // console.log("REDUX - calendar openedDetails (set)");
            return {
                ...state,
                openedDetails: [...state.openedDetails, action.payload.value],
            };
        case CalendarActionTypes.SET_CREATE_EVENT_MODE:
            // console.log("REDUX - calendar createEventMode");
            return { ...state, createEventMode: action.payload.value };
        case CalendarActionTypes.SET_SALES_MODE:
            // console.log("REDUX - calendar salesMode");
            return { ...state, salesMode: action.payload.value };
        case CalendarActionTypes.SET_SELECTED_EVENT:
            // console.log("REDUX - calendar selectedEvent");
            return { ...state, selectedEvent: action.payload.value };
        case CalendarActionTypes.SET_SELECTED_TEMPLATE_ID:
            // console.log("REDUX - calendar selectedTemplateId");
            return { ...state, selectedTemplateId: action.payload.value };
		case CalendarActionTypes.SET_SELECTED_EVENT_ID_FOR_PLANNING:
			// console.log("REDUX - calendar selectedTemplateId");
			return { ...state, selectedEventIdForPlanning: action.payload.value };
        case CalendarActionTypes.SET_NEW_EVENT:
            // console.log("REDUX - calendar newEvent");
            return { ...state, newEvent: action.payload.value };
        case CalendarActionTypes.SET_LOADING:
            // console.log("REDUX - calendar isLoading");
            return { ...state, isLoading: action.payload.value };
		case CalendarActionTypes.SET_SHOULD_AUTO_REFRESH:
			// console.log("REDUX - calendar shouldAutoRefresh");
			return { ...state, shouldAutoRefresh: action.payload.value };
        case CalendarActionTypes.SET_CALENDAR_SETTINGS:
            // console.log("REDUX - calendar calendarSettings");
            return { ...state, calendarSettings: action.payload.value };
        case CalendarActionTypes.SET_CALENDAR_EVENT_SORT:
            return { ...state, eventSortOption: action.payload.value };
        default:
            return state;
    }
}

export default calendarReducer;
