import { UserActionTypes } from "../actionTypes";

export interface UserType {
	islogged: boolean;
	branchnr?: string;
	language?: string;
	languagenr?: string;
	username?: string;
	userno?: string;
}

const initUserState = {
	islogged: false,
}

function userReducer(
    state: UserType = initUserState,
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case UserActionTypes.SET_USER:
            // console.log("REDUX - set user");
            return action.payload.value;
        default:
            return state;
    }
}

export default userReducer;
