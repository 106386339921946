import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { debounce } from "lodash";

import {
    deleteEventFromExternalAPI,
    deleteEventsFromAPI,
    getEventsFromAPI,
    mergeEventsFromAPI,
} from "../../helpers/api";
import Row from "../Layout/Row";
import Col from "../Layout/Col";
import MiniCalendar from "./MiniCalendar";
import SelectField from "../Fields/SelectField";
import RadioLikeField from "../Fields/RadioLikeField";
import CheckboxField from "../Fields/CheckboxField";
import ButtonField from "../Fields/ButtonField";
import { CalendarDisplayType } from "../Calendar/Calendar";
import Modal from "../Layout/Modal";
import { getStartEndDates } from "../../helpers/dateHelpers";
import { getFilters } from "../../redux/selectors/filterSelectors";
import {getCheckedEventIds, getSalesMode, getShouldAutoRefresh} from "../../redux/selectors/calendarSelectors";
import {
	setCalendarIdFilter,
	setBranchFilter,
	setBranchGroupFilter,
	setCalendarDisplayTypeFilter,
	setEventsWithoutOrderFilter,
	setPickedDateFilter,
	setResourceGroupFilter,
	setTemplateFilter, setTemplateGroupFilter, setResourceFilter, setSearchFilter, setSpecialResourceFilter, setShowBlockersFilter,
} from "../../redux/actions/filterActions";
import {
	setCheckedEventIds,
	setLoading, setSalesMode, setShouldAutoRefresh,
} from "../../redux/actions/calendarActions";
import { setEvents } from "../../redux/actions/eventsActions";
import { getFilterOptions } from "../../redux/selectors/filterOptionsSelectors";
import { setPrintMode } from "../../redux/actions/printModeActions";
import {setBranchItems} from "../../redux/actions/branchItemsActions";
import SearchField from "../Fields/SearchField/SearchField";
import TextField from "../Fields/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getStoreManagerURL } from "../../redux/selectors/appConfigSelectors";

const FilterPanel: FC = () => {
    // console.log(">>> FILTER PANEL");
    const dispatch = useDispatch();
    const filters = useSelector(getFilters);
    const filterOptions = useSelector(getFilterOptions);
    const checkedEventIds = useSelector(getCheckedEventIds);
	const shouldAutoRefresh = useSelector(getShouldAutoRefresh);
    const salesMode = useSelector(getSalesMode);
    const storeManagerURL = useSelector(getStoreManagerURL);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [mergeModalOpen, setMergeModalOpen] = useState(false);
    const { t } = useTranslation();

    const handlePickDate = (date: Date | Date[]) => {
		console.log(date);
        const d = Array.isArray(date)
            ? date.length
                ? date[0]
                : new Date()
            : date;
        const pickedDate = new Date(d.setHours(0, 0, 0, 0));
        dispatch(setPickedDateFilter(pickedDate));
    };

    const handleDisplayTypeChange = (value: CalendarDisplayType) => {
        dispatch(setCalendarDisplayTypeFilter(value));
    };

    // const handleUpdate = () => {
    //     // updateEvents(events)
    //     //     .then(() => {
    //     //         console.log("saved");
    //     //     })
    //     //     .catch((err) => console.log(err));
    // };

    const handleDelete = () => {
        if (checkedEventIds.length) {
            setDeleteModalOpen(true);
        }
    };

    const handlePrint = () => {
        dispatch(setPrintMode(true));
    };

    const onDelete = () => {
        deleteEventsFromAPI(checkedEventIds)
            .then(() => {
                deleteEventFromExternalAPI(storeManagerURL, checkedEventIds.join(','))
                .then((resp) => {
                    console.log(resp);
                })
                .catch((err) => {
                    console.log(err);
                });
                dispatch(setCheckedEventIds([]));
                const [startDate, endDate] = getStartEndDates(
                    filters.pickedDate,
                    filters.calendarDisplayType
                );
                dispatch(setLoading(true));
                getEventsFromAPI({
                    from: format(startDate, "yyyy-MM-dd"),
                    to: format(endDate, "yyyy-MM-dd"),
                    eventsWithoutOrder: filters.eventsWithoutOrder,
                }).then((res) => {
                    dispatch(setEvents(res.events));
					dispatch(setBranchItems(res.branchItems));
                    dispatch(setLoading(false));
                });
            })
            .catch((err) => console.log(err));
    };

    // const handleMerge = () => {
    //     if (checkedEventIds.length) {
    //         setMergeModalOpen(true);
    //     }
    // };

    const onMerge = () => {
        mergeEventsFromAPI(checkedEventIds)
            .then(() => {
                dispatch(setCheckedEventIds([]));
                const [startDate, endDate] = getStartEndDates(
                    filters.pickedDate,
                    filters.calendarDisplayType
                );
                dispatch(setLoading(true));
                getEventsFromAPI({
                    from: format(startDate, "yyyy-MM-dd"),
                    to: format(endDate, "yyyy-MM-dd"),
                    eventsWithoutOrder: filters.eventsWithoutOrder,
                }).then((res) => {
                    dispatch(setEvents(res.events));
                    dispatch(setBranchItems(res.branchItems));
                    dispatch(setLoading(false));
                });
            })
            .catch((err) => console.log(err));
    };

    function getBranchOptions(branchGroupValue: string | null) {
        let branches;
        if (branchGroupValue !== null) {
            branches = filterOptions.branch.filter(
                (b) => b.groupValue === branchGroupValue
            );
        } else {
            branches = filterOptions.branch;
        }
        return branches.map((b) => {
            return {
                value: b.value,
                label: b.label,
            };
        });
    }

	function getTemplateOptions(templateGroupValue: string | null, branchValue: string | null) {
		let templates;
		if (templateGroupValue !== null) {
			templates = filterOptions.template.filter(
				(t) => t.groupNr === templateGroupValue
			);
		} else {
			templates = filterOptions.template;
		}
		if (branchValue !== null) {
			templates = templates.filter((t) => t.branchNr === branchValue);
		}
		return templates.map((t) => {
			return {
				value: t.value,
				label: t.label,
			};
		}).sort((a, b) => a.label.localeCompare(b.label));
	}

    function getResourcesOptions(resourceGroupValue: string | null, branchValue: string | null) {
        let resources;
        if (resourceGroupValue !== null) {
            resources = filterOptions.resource.filter(
                (res) => res.groupValue === resourceGroupValue
            );
        } else {
            resources = filterOptions.resource;
        }
        if (branchValue !== null) {
			resources = resources.filter((r) => r.branchId === branchValue);
		}
        return resources.map((res) => {
            return {
                value: res.value,
                label: res.label,
            };
        });
    }

	function speacialResourceFilter() {
		return [
			{
				value: 'A',
				label: t("resources_without_invitation"),
			},
			{
				value: 'B',
				label: t("events_with_missing_assignments"),
			},
			{
				value: 'C',
				label: t("events_with_missing_assignments_and_resources_without_invitation"),
			}
		]
	}

    return (
        <div className="filter-panel">
            <Row gap="5px">
                <Col className="mini-calendar-container" width={4} gap="5px">
                    <MiniCalendar
                        value={filters.pickedDate}
                        onChange={handlePickDate}
                    />
                </Col>
                <Col width={12} gap="5px">
                    <Row gap="5px">
                        <Col width={3} gap="5px">
                            <SelectField<string>
                              value={filters.calendarId}
                              onChange={(val) =>
                                dispatch(setCalendarIdFilter(val))
                              }
                              options={filterOptions.calendars.map((c) => {
                                    return {
                                        value: c.value,
                                        label: t(c.label),
                                    };
                              })}
                              label={t("calendar")}
                            />
                        </Col>
                    </Row>
                    <Row gap="5px">
                        <Col width={3} gap="5px">
                            <SelectField<string>
                                value={filters.branchGroup}
                                onChange={(val) =>
                                    dispatch(setBranchGroupFilter(val))
                                }
                                options={filterOptions.branchGroup}
                                label={t("branch_group")}
                            />
                        </Col>
                        <Col width={3} gap="5px">
                            <SelectField<string>
                                value={filters.templateGroup}
                                onChange={(val) => {
									console.log(val);
									dispatch(setTemplateGroupFilter(val))
								}}
                                options={filterOptions.templateGroup}
                                label={t("template_group")}
                            />
                        </Col>
                        <Col width={3} gap="5px">
                            <SelectField<string>
                                value={filters.resourceGroup}
                                onChange={(val) =>
                                    dispatch(setResourceGroupFilter(val))
                                }
                                options={filterOptions.resourceGroup}
                                label={t("resources_group")}
                            />
                        </Col>
                        <Col width={3} gap="5px">
                            {/*<SelectField<number>*/}
                            {/*    value={null}*/}
                            {/*    onChange={() => {}}*/}
                            {/*    options={[]}*/}
                            {/*    label={t("unasigned_resources")}*/}
                            {/*/>*/}
                        </Col>
                    </Row>
                    <Row gap="5px">
                        <Col width={3} gap="5px">
                            <SelectField<string>
                                value={filters.branch}
                                onChange={(val) =>
                                    dispatch(setBranchFilter(val))
                                }
                                options={getBranchOptions(
                                    filters.branchGroup && filters.branchGroup
                                )}
                                label={t("branch")}
                            />
                        </Col>
                        <Col width={3} gap="5px">
							{/*<SearchField<string>*/}
							{/*	value={filters.template}*/}
							{/*	onChange={(val) =>*/}
							{/*		console.log(val)*/}
							{/*		// dispatch(setTemplateFilter(val))*/}
							{/*	}*/}
							{/*	searchOptions={filterOptions.template}*/}
							{/*	label={t("template")}*/}
							{/*/>*/}
							<SelectField<string>
								value={filters.template}
								onChange={(val) =>
									dispatch(setTemplateFilter(val))
								}
								options={getTemplateOptions(
									filters.templateGroup && filters.templateGroup,
									filters.branch && filters.branch
								)}
								label={t("template")}
							/>
							{/*<Select*/}
							{/*	menuPortalTarget={document.body}*/}
							{/*	styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}*/}
							{/*	options={filterOptions.grouppedTemplates}*/}
							{/*	onChange={(val) => {*/}
							{/*		// @ts-ignore*/}
							{/*		dispatch(setTemplateFilter(val.value));*/}
							{/*	}}*/}
							{/*/>*/}
                        </Col>
                        <Col width={3} gap="5px">
                            <SearchField<string>
                                value={filters.resource}
                                onChange={(val: any) =>
                                    dispatch(setResourceFilter(val))
                                }
                                searchOptions={getResourcesOptions(
                                    filters.resourceGroup && filters.resourceGroup,
                                    filters.branch && filters.branch
                                )}
                                label={t("resources")}
                            />
                        </Col>
                        <Col width={3} gap="5px">
                            <TextField
                                value={filters.search}
                                onChange={(val) => dispatch(setSearchFilter(val))}
                                label={t("search_in_event_description")}
                            />
                        </Col>
                    </Row>
                    <Row gap="5px">
                        <Col width={3} gap="5px">
                            <CheckboxField
                                value={filters.eventsWithoutOrder}
                                label={t("events_without_an_order")}
                                onChange={(val: boolean) =>
                                    dispatch(setEventsWithoutOrderFilter(val))
                                }
                            />
                        </Col>
                        <Col width={3} gap="5px">
                            <CheckboxField
                                value={filters.showBlockers}
                                label={t("show_blockers")}
                                onChange={(val: boolean) =>
                                    dispatch(setShowBlockersFilter(val))
                                }
                            />
                        </Col>
						<Col width={3} gap="5px">
							<SelectField<string>
								value={filters.specialResource}
								onChange={(val) =>
									dispatch(setSpecialResourceFilter(val))
								}
								options={speacialResourceFilter()}
								label={t("invitation_assignment")}
							/>
						</Col>
						<Col width={3} gap="5px">
							<CheckboxField
								value={shouldAutoRefresh}
								label={t("auto_refresh")}
								onChange={(val: boolean) =>
									dispatch(setShouldAutoRefresh(val))
								}
							/>
						</Col>
                    </Row>
                </Col>
                <Col width={3} gap="5px">
                    <Row gap="5px">
                        <Col width={1} gap="5px">
                            <RadioLikeField<CalendarDisplayType>
                                value={filters.calendarDisplayType}
                                options={[
                                    { value: "day", label: "D" },
                                    { value: "week", label: "W" },
                                    { value: "month", label: "M" },
                                ]}
                                onChange={handleDisplayTypeChange}
                            />
                        </Col>
                        <Col width={2} gap="5px">
                            {/* <ButtonField onClick={handlePrint}>
                                {t("print")}
                            </ButtonField> */}
                            {/* <ButtonField onClick={handleUpdate}>
                                {t("update")}
                            </ButtonField> */}
                        </Col>
                    </Row>
                    <Row gap="5px">
                        <Col>
                            <ButtonField onClick={handleDelete}>
                                {t("delete_events")}
                            </ButtonField>
                        </Col>
                    </Row>
                    <Row gap="5px">
                        <Col>
                            <FormControlLabel
                                style={{marginTop: 12}}
                                control={
                                <Switch
                                    checked={salesMode}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        dispatch(setSalesMode(event.target.checked))
                                    }
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label={t("sales_mode")}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                className="confirmation-modal"
                open={deleteModalOpen}
                eventModal={false}
                onClose={() => setDeleteModalOpen(false)}
                onCancel={() => setDeleteModalOpen(false)}
                cancelBtnText={t("cancel")}
                onSubmit={() => {
                    setDeleteModalOpen(false);
                    onDelete();
                }}
                submitBtnText={t("delete")}
                title={t("confirm_deleting_events")}
            >
                {t("do_you_want_to_delete_events")}
            </Modal>
            <Modal
                className="confirmation-modal"
                open={mergeModalOpen}
                eventModal={false}
                onClose={() => setMergeModalOpen(false)}
                onCancel={() => setMergeModalOpen(false)}
                cancelBtnText={t("cancel")}
                onSubmit={() => {
                    setMergeModalOpen(false);
                    onMerge();
                }}
                submitBtnText={t("merge")}
                title={t("confirm_merging_events")}
            >
                {t("do_you_want_to_merge_events")}
            </Modal>
        </div>
    );
};

export default FilterPanel;
